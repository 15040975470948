<template>
	<a-modal wrap-class-name="modal-authorize" :width="424" :visible="value" :title="null" :footer="null" :centered="true" @cancel="onCancel">
		<div class="modal-content">
			<div class="modal-header">
				<img class="logo" src="../assets/img/logo1.png" />
				<a class="modal-close" data-dismiss="modal" href="javascript:;" @click="onCancel"></a>
			</div>
			<div class="modal-body">
				<div class="modal-tabs tabs-3" v-if="AUTHORIZE_TYPE.RESET == type">
					<a href="javascript:;" class="active">找回密码</a>
					<a href="javascript:;" @click="changeAuthorizeType(AUTHORIZE_TYPE.LOGIN_PASSWORD)">返回登录</a>
				</div>
				<div class="modal-tabs tabs-4" v-else-if="AUTHORIZE_TYPE.REGISTER == type">
					<a href="javascript:;" class="active">注册账号</a>
					<a href="javascript:;" @click="changeAuthorizeType(AUTHORIZE_TYPE.LOGIN_PASSWORD)">返回登录</a>
				</div>
				<div class="modal-tabs tabs-1" v-else>
					<a href="javascript:;" :class="[AUTHORIZE_TYPE.LOGIN_PASSWORD == type ? 'active' : '']" @click="changeAuthorizeType(AUTHORIZE_TYPE.LOGIN_PASSWORD)">账号登录</a>
					<a href="javascript:;" :class="[AUTHORIZE_TYPE.LOGIN_CODE == type ? 'active' : '']" @click="changeAuthorizeType(AUTHORIZE_TYPE.LOGIN_CODE)">验证码登录</a>
				</div>
				<form class="form" @submit.prevent="onSubmit">
					<div class="form-group" v-if="[AUTHORIZE_TYPE.LOGIN_PASSWORD].includes(type)">
						<input placeholder="请输入手机号码" name="username" v-model.trim="formdata.username" />
					</div>
					<div class="form-group" v-if="[AUTHORIZE_TYPE.LOGIN_CODE, AUTHORIZE_TYPE.REGISTER, AUTHORIZE_TYPE.RESET].includes(type)">
						<input placeholder="请输入手机号码" name="phone" v-model.trim="formdata.phone" />
					</div>

					<ws-password
						v-if="[AUTHORIZE_TYPE.LOGIN_PASSWORD, AUTHORIZE_TYPE.REGISTER].includes(type)"
						v-model.trim="formdata.password"
						placeholder="请输入登录密码"
					></ws-password>

					<ws-password v-if="[AUTHORIZE_TYPE.REGISTER].includes(type)" v-model.trim="formdata.confirm_passowrd" placeholder="请确认登录密码"></ws-password>

					<ws-password v-if="[AUTHORIZE_TYPE.RESET].includes(type)" v-model.trim="formdata.npassword" placeholder="请输入新的登录密码"></ws-password>

					<ws-password v-if="[AUTHORIZE_TYPE.RESET].includes(type)" v-model.trim="formdata.confirm_npassowrd" placeholder="请确认新的登录密码"></ws-password>

					<div class="form-group form-captcha" v-if="false">
						<input placeholder="请输入图片验证码" name="imgcode" v-model.trim="formdata.imgcode" />
						<img src="" />
					</div>

					<div class="form-group" v-if="[AUTHORIZE_TYPE.REGISTER, AUTHORIZE_TYPE.LOGIN_CODE, AUTHORIZE_TYPE.RESET].includes(type)">
						<input placeholder="请输入验证码" name="code" v-model.trim="formdata.code" />
						<span class="msgcode" @click="getCode">{{ time > 0 ? `${time}s后重新获取` : '获取验证码' }}</span>
					</div>

					<div class="form-meta" v-if="[AUTHORIZE_TYPE.LOGIN_PASSWORD].includes(type)">
						<span style="flex: 1"></span>
						<a href="javascript:;" @click="changeAuthorizeType(AUTHORIZE_TYPE.RESET)">忘记密码</a>
					</div>

					<div class="form-terms" v-if="[AUTHORIZE_TYPE.REGISTER].includes(type)">
						<!-- <input type="checkbox" v-model="formdata.agree" /> -->
						<img src="../assets/img/icon-checkbox-checked.png" v-if="formdata.agree" @click="formdata.agree = false" />
						<img src="../assets/img/icon-checkbox.png" v-else @click="formdata.agree = true" />
						<!-- <a-checkbox v-model="formdata.agree" style="margin-right: 4px;" /> -->
						同意
						<a href="/disclaimer" target="_blank">《用户注册协议》</a>
					</div>
					<div class="form-action" v-if="[AUTHORIZE_TYPE.LOGIN_PASSWORD, AUTHORIZE_TYPE.LOGIN_CODE].includes(type)">
						<a-button :loading="loading" type="primary" html-type="submit">登 录</a-button>
					</div>
					<div class="form-action" v-else-if="[AUTHORIZE_TYPE.REGISTER].includes(type)">
						<a-button :loading="loading" type="primary" html-type="submit">注 册</a-button>
					</div>
					<div class="form-action" v-else-if="[AUTHORIZE_TYPE.RESET].includes(type)"><a-button :loading="loading" type="primary" html-type="submit">重 置</a-button></div>
					<div class="form-meta" v-if="[AUTHORIZE_TYPE.LOGIN_PASSWORD, AUTHORIZE_TYPE.LOGIN_CODE].includes(type)">
						<span style="flex: 1"></span>
						<a href="javascript:;" style="color: #ed4b65" @click="changeAuthorizeType(AUTHORIZE_TYPE.REGISTER)">新用户注册</a>
					</div>
				</form>
			</div>
		</div>
	</a-modal>
</template>

<script>
import { AUTHORIZE_TYPE } from '@/utils/constants.js';
import wsPassword from '@/components/wsPassword.vue';
const FORMDATA = {
	phone: '',
	username: '',
	password: '',
	confirm_passowrd: '',
	npassword: '',
	confirm_npassowrd: '',
	code: '',
	imgcode: '',
	// nickname: '',
	agree: false
};
export default {
	name: 'wsAuthorize',
	components: {
		wsPassword
	},
	props: {
		value: {
			type: Boolean
		},
		type: {
			type: [String, Number]
		}
	},
	data() {
		return {
			AUTHORIZE_TYPE,
			formdata: Object.assign({}, FORMDATA),
			time: 0,
			loading: false
		};
	},
	computed: {
		routeName() {
			return this.$route.name.toLowerCase();
		}
	},
	methods: {
		changeAuthorizeType(type) {
			this.$emit('update:type', type);
		},
		async getCode() {
			const { phone } = this.formdata;
			if (!/^1[3-9]\d{9}$/.test(phone)) {
				return this.$message.error('手机号码格式不正确');
			}
			const response = await this.$api.post('/web/send_sms', { phone });
			if (response && response.code == 200) {
				this.time = 60;
				let timer = setInterval(() => {
					if (this.time-- < 1) {
						clearImmediate(timer);
						this.time = 0;
					}
				}, 1000);
			}
		},
		onCancel() {
			this.formdata = Object.assign({}, FORMDATA);
			this.$emit('input', false);
		},
		async onSubmit() {
			const { phone, username, password, confirm_passowrd, npassword, confirm_npassowrd, code, imgcode, agree } = this.formdata;
			var url = '';
			var data = {};
			this.loading = true;
			switch (this.type) {
				case AUTHORIZE_TYPE.REGISTER:
					url = '/web/register';
					data = {
						phone,
						password,
						code
					};
					break;
				case AUTHORIZE_TYPE.RESET:
					url = '/web/reset_password';
					data = { phone, password: npassword, code };
					break;
				case AUTHORIZE_TYPE.LOGIN_PASSWORD:
					url = '/web/login';
					data = { username, password };
					break;
				case AUTHORIZE_TYPE.LOGIN_CODE:
					url = '/web/check_code';
					data = { phone, code };
					break;
			}
			if (this.type == AUTHORIZE_TYPE.REGISTER && !agree) {
				this.loading = false;
				this.$message.error('请阅读并同意用户注册协议');
				return;
			}
			var errors = [];
			Object.keys(data).forEach(key => {
				if (key == 'phone') {
					data.phone || errors.push('请输入手机号码');
					/^1[3-9]\d{9}$/.test(data.phone) || errors.push('手机号码格式不正确');
				} else if (key == 'username') {
					data.username || errors.push('请输入昵称或手机号码');
				} else if (key == 'password') {
					data.password || errors.push('请输入登录密码');
					if (this.type == AUTHORIZE_TYPE.REGISTER && password != confirm_passowrd) {
						errors.push('两次输入密码不一致');
					}
					if (this.type == AUTHORIZE_TYPE.RESET && npassword != confirm_npassowrd) {
						errors.push('两次输入密码不一致');
					}
				} else if (key == 'code') {
					/^\d{4}$/.test(data.code) || errors.push('请输入4位数字验证码');
				}
			});

			if (errors.length) {
				this.loading = false;
				this.$message.error(errors[0]);
				return;
			}

			const response = await this.$api.post(url, data).catch(e => {
				this.loading = false;
			});
			if (response) {
				const { token, ...minfo } = response.data;
				this.$storage.set('token', token);
				this.$storage.set('minfo', minfo);
				location.reload();
			}
		}
	}
};
</script>
